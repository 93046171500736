import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/style.css"
import {useState} from "react";
import designGif from '../images/product/design-certificate.gif'
import groupGif from '../images/product/create-group.gif'
import certificateGIF from '../images/product/publish-certificate.gif'
import {ChevronRight, MaterialAdd, NorthEast} from "../components/material-icons";

const IndexPage = () => {

    const faqs = [
        {
            "question": "What is Hyperstack?",
            "answer": "Hyperstack is a blockchain-based digital credentialing platform that enables and automates the delivery of digital certificates, badges, transcripts, diplomas and other credentials. Founded in 2018, Hyperstack aims to let its clients create, manage, issue, and share online certificates as easily and quickly as possible.",
        },
        {
            "question": "How Does Hyperstack work?",
            "answer": "Hyperstack allows you to create and manage digital credentials, automating the generation process and validates its authenticity with blockchain technology. Hyperstack uses the Hedera Hashgraph to store and verify credentials.",
        },
        {
            "question": "What is the Hedera hashgraph?",
            "answer": "The Hedera Hashgraph is an advanced blockchain infrastructure for enterprises that enables the no-trust authenticity requirements for Hyperstack.",
        },
        {
            "question": "What is a smart digital certificate?",
            "answer": "A smart digital certificate, unlike a PDF certificate, is interactive, shareable, verifiable and legitimate, and gives credibility to both the recipient as well as the issuing authority.",
        },
        {
            "question": "How is it different from traditional PDF certificates?",
            "answer": "PDF Certificates hold little to no value as they are easy to fake or be tampered with, and validation is impossible, whereas smart digital certificates issued by Hyperstack can be verified and issued in bulk.",
        },
    ]

    const [faqShown, setFaqShown] = useState('');
    const [plantype, setPlantype] = useState(0);
    const [showVideo, setShowVideo] = useState(false);

    const toggleFaq = (id) => {
        setFaqShown(faqShown === id ? '0' : id);
    };


    return (<Layout>
        <section>
            <aside>
                <h1>The Premier Service for <br/>Digital Certificates and Badges</h1>
                <p>Design, issue, share and verify credentials with ease using Hyperstack's comprehensive design and
                    credential management platform.</p>
                <Link className={"button--icon"} target="_blank"
                      href={"https://studio.thehyperstack.com/logon?ref=heroCTA"}>
                    Start Now, It's Free
                    {/*<span className="material-symbols-rounded">chevron_right</span>*/}
                    <ChevronRight color={"#FFFFFF"} size={"20px"}/>
                </Link>
                <p className={"sub"}>No Credit Card, Required!</p>
            </aside>
            <aside>
                <div className={showVideo ? "forcehide" : null} onClick={() => setShowVideo(true)}>
                    <StaticImage alt="Hyperstack Video Thumbnail"
                                 className={"thumbnail pointer"}
                                 src="../images/video-thumbnail.png"/>
                </div>
                {showVideo ?
                    (<iframe width="100%" height="260"
                             src="https://www.youtube.com/embed/kzaLvAbtXkI?autoplay=1&loop=1&rel=0&wmode=transparent"
                             frameborder="0" allowfullscreen wmode="Opaque"
                             className={showVideo ? "" : "forcehide"}></iframe>) : null}
            </aside>
        </section>

        <section className={"section--row hcenter"}>
            <p>Loved by popular brands around the world</p>
            <div className={"brand-logos"}>
                <StaticImage src={"../images/logos/logo-niti-aayog.png"}
                             height={38} layout="fixed"
                             imgClassName={"brand-logo"}
                             alt={"Hyperstack Customers"}/>
                <StaticImage src={"../images/logos/logo-santa-clara.png"}
                             height={38} imgClassName={"brand-logo"} layout="fixed"
                             alt={"Hyperstack Customers"}/>
                <StaticImage src={"../images/logos/logo-stanford-medicine.png"}
                             height={28} imgClassName={"brand-logo"}
                             layout="fixed" quality="100"
                             alt={"Hyperstack Customers"}/>
                <StaticImage src={"../images/logos/logo-dell.png"}
                             height={26} layout="fixed"
                             imgClassName={"brand-logo"}
                             alt={"Hyperstack Customers"}/>
                <StaticImage src={"../images/logos/logo-techstars.png"}
                             height={30} imgClassName={"brand-logo"} layout="fixed"
                             alt={"Hyperstack Customers"}/>
                <StaticImage src={"../images/logos/logo-millercenter.png"}
                             height={37} imgClassName={"brand-logo"} layout="fixed"
                             alt={"Hyperstack Customers"}/>
            </div>
        </section>

        <section className={"section--spacious"}>
            <aside>
                <h2 className="title">Check out our performance <br/>score from G2 Crowd</h2>
                <p>Loved by thousands of customers around the world. Rated as <br/>the best and user friendly Credential
                    management
                    <br/>software platform by G2 Crowd.</p>

                <Link target="_blank" rel="noopener noreferrer"
                      to="https://www.g2.com/products/hyperstack-credential-cloud/reviews">
                    <StaticImage src={"../images/g2-rating.png"} height={22} layout="fixed"
                                 alt={"G2 Rating"}/>
                </Link>
            </aside>
            <aside>
                <Link target="_blank" rel="noopener noreferrer"
                      to="https://www.g2.com/products/hyperstack-credential-cloud/reviews">
                    <StaticImage src={"../images/g2-badges.png"} alt={"Hyperstack's G2 Badges"}/>
                </Link>
            </aside>
        </section>

        <section className={"section--row"}>
            <h2 className="title">Used by the world’s most <br/>Popular Brands</h2>
            <a target="_blank" rel="noopener noreferrer"
               href="https://www.g2.com/products/hyperstack-credential-cloud/reviews">Read All Customer
                Reviews <NorthEast color={"#666666"} size={"20px"}/></a>

            <div className={"testimonials"}>
                <a target="_blank" rel="noopener noreferrer"
                   href="https://www.g2.com/products/hyperstack-credential-cloud/reviews/hyperstack-credential-cloud-review-5302263"
                   className={"testimonial"}>
                    <StaticImage src={"../images/logos/logo-niti-aayog.png"}
                                 height={38} layout="fixed"
                                 imgClassName={"brand-logo"}
                                 alt={"Hyperstack Customers"}/>
                    <p>"Hyperstack has made awarding certifications so easy that we were able to reward over 20,000
                        users in under 10 minutes. Moreover, our users received customized personal emails."</p>
                    <div className={"horizontal"}>
                        <StaticImage
                            src={"https://images.g2crowd.com/uploads/avatar/image/592914/thumb_square_a07ec2edf0fd79115523d24dc728c8ed.jpeg"}
                            height={47} layout="fixed" style={{borderRadius: "200px"}}
                            alt={"Hyperstack Customers"}/>
                        <div>
                            <p className={"head"}>Swati Rao</p>
                            <p className={"sub"}>Innovation Head</p>
                        </div>
                    </div>
                </a>
                <a target="_blank" rel="noopener noreferrer"
                   href="https://www.g2.com/products/hyperstack-credential-cloud/reviews/hyperstack-credential-cloud-review-5311908"
                   className={"testimonial"}>
                    <StaticImage src={"../images/logos/logo-millercenter.png"}
                                 height={38} layout="fixed"
                                 imgClassName={"brand-logo"}
                                 alt={"Hyperstack Customers"}/>
                    <p>"At Miller Center, we run several different programs a year and Hyperstack makes it possible to
                        send program certificates in a fraction of the time that it used to."</p>
                    <div className={"horizontal"}>
                        <StaticImage
                            src={"https://images.g2crowd.com/uploads/avatar/image/595112/thumb_square_a863b822ef0f03f1250aa061316a1ba1.jpeg"}
                            height={47} layout="fixed" style={{borderRadius: "200px"}}
                            alt={"Hyperstack Customers"}/>
                        <div>
                            <p className={"head"}>Thao Nguyen</p>
                            <p className={"sub"}>Program Manager & Floral Designer</p>
                        </div>
                    </div>
                </a>
                <a target="_blank" rel="noopener noreferrer"
                   href="https://www.g2.com/products/hyperstack-credential-cloud/reviews/hyperstack-credential-cloud-review-5349088"
                   className={"testimonial"}>
                    <StaticImage src={"../images/logos/logo-thecge.svg"}
                                 height={32} layout="fixed"
                                 imgClassName={"brand-logo"}
                                 alt={"Hyperstack Customers"}/>
                    <p>"Hyperstack is very user-friendly and easy to maneuver! I like that I can easily send
                        certificates to my learners without writing dozens of personalized emails. It has made my job
                        more efficient!"</p>
                    <div className={"horizontal"}>
                        <StaticImage src={"../images/circle.png"}
                                     height={47} layout="fixed"
                                     alt={"Hyperstack Customers"}/>
                        <div>
                            <p className={"head"}>Mimi Murphy</p>
                            <p className={"sub"}>Program Manager</p>
                        </div>
                    </div>
                </a>
            </div>
        </section>

        <section className={"section--row"}>
            <h2 className="title">Go live in <br/><span className={"text-blue"}>three simple steps...</span></h2>
            <p>Issue thousands of digital certificates and badges in just <br/>less than 2 minutes with three simple
                steps</p>

            <div className="timeline">
                <div className="timeline-container right">
                    <div className="timeline-content">
                        <div>
                            <h3 className="title">Design</h3>
                            <p>
                                Design your digital certificate or badge with <br/>our comprehensive design studio
                            </p>
                        </div>
                        <div className={"video"}>
                            <div className={"video-head"}>
                                <span className={"video-head__action video-head__action--red"}></span>
                                <span className={"video-head__action video-head__action--yellow"}></span>
                                <span className={"video-head__action video-head__action--green"}></span>
                            </div>
                            <img loading={"lazy"} src={designGif} alt={"Hyperstack Workflow Step 1"}/>
                        </div>
                    </div>
                </div>

                <div className="timeline-container right">
                    <div className="timeline-content">
                        <div>
                            <h3 className="title">Create</h3>
                            <p>
                                Create your credential group with a title,<br/>
                                description and appearance.
                            </p>
                        </div>
                        <div className={"video"}>
                            <div className={"video-head"}>
                                <span className={"video-head__action video-head__action--red"}></span>
                                <span className={"video-head__action video-head__action--yellow"}></span>
                                <span className={"video-head__action video-head__action--green"}></span>
                            </div>
                            <img loading={"lazy"} src={groupGif} alt={"Hyperstack Workflow Step 2"}/>
                        </div>
                    </div>
                </div>
                <div className="timeline-container right">
                    <div className="timeline-content">
                        <div>
                            <h3 className="title">Issue</h3>
                            <p>
                                Upload your spreadsheet and issue certificates<br/>
                                or badges to your students or employees instantly.
                            </p>
                            <Link target="_blank"
                                  href="https://hyperstack.id/credential/0df79da1-2dba-4f10-8498-1a7b811d7b63?view=recipient"
                                  className={"text-blue"}>See Sample Certificate
                                <NorthEast color={"#485BFF90"} size={"18px"}/>
                            </Link>
                            <Link className={"button"} target="_blank"
                                  href="https://studio.thehyperstack.com/logon?ref=stepsCTA">
                                Try for free
                            </Link>
                        </div>
                        <div className={"video"}>
                            <div className={"video-head"}>
                                <span className={"video-head__action video-head__action--red"}></span>
                                <span className={"video-head__action video-head__action--yellow"}></span>
                                <span className={"video-head__action video-head__action--green"}></span>
                            </div>
                            <img loading={"lazy"} src={certificateGIF} alt={"Hyperstack Workflow Step 3"}/>
                        </div>
                    </div>
                </div>
            </div>

        </section>

        <section className={"section--row section--spacious"}>
            <h2 className="title">Your Ultimate Tool For All Your<br/><span
                className={"text-blue"}>Digital Certificate and Badge Management</span></h2>
            <p>
                It’s not just about infrastructure and underlying technology, Hyperstack provides <br/>a whole range of
                features that will enable you to take customization and branding to a whole new level.

            </p>
            <br/>
        </section>

        <section className={"section--reverse"}>
            <aside>
                <h3 className="title">Branding & White-labeling</h3>
                <p>At Hyperstack, you can issue your digital certificates and badges from your domain and email address
                    with completely customizable branding on your digital credential, as well as your own verification
                    page.</p>
            </aside>
            <aside>
                <StaticImage src={"../images/product/branding.png"} alt={"Hyperstack Branding"}/>
            </aside>
        </section>

        <section>
            <aside>
                <h3 className="title">Dedicated <span className="text-blue">Verification Page</span></h3>
                <p>Hyperstack’s platform offers a highly dynamic built-in blockchain solution to verify your digital
                    credentials which can be used by background verification agencies and recruiters. This helps in
                    preventing huge waiting times and faster hiring of your students.
                </p>
            </aside>
            <aside>
                <br/>
                <StaticImage src={"../images/product/verification-page.png"}
                             alt={"Hyperstack Dedicated Verification Page"}/>
                <br/>
            </aside>
        </section>

        <section className={"section--reverse"}>
            <aside>
                <h3 className="title"><span className="text-blue">Advanced Analytics</span> with 20+ Insights</h3>
                <p>Our measurements and insights module allows for multiple data points about your students and allows
                    prospects to be captured which in turn can be fed into your marketing and sales strategies.</p>
            </aside>
            <aside>
                <StaticImage src={"../images/product/analytics.png"} alt={"Hyperstack Analytics"}/>
            </aside>
        </section>

        <section>
            <aside>
                <h3 className="title"><span className="text-blue">Multi-Blockchain</span> Support</h3>
                <p>Our blockchain based system makes sure your certificates are authentic and secures your
                    organizational identity and eliminates the possibility of fraud. With our multi-blockchain support
                    you can issue digital credentials on any blockchain with your own preference.
                </p>
            </aside>
            <aside>
                <StaticImage src={"../images/product/multi-blockchain.png"} alt={"Hyperstack Blockchain"}/>
            </aside>
        </section>

        <section className={"section--reverse"}>
            <aside>
                <h3 className="title">Powerful <span className="text-blue">Design Studio</span></h3>
                <p>Our Design Studio allows for native design of digital badges and certificates which support standards
                    such as open badges 2.0. We support integration with popular platforms such as Canva which allows
                    for professional design to be completed or predesigned credentials to be uploaded very quickly.
                </p>
            </aside>
            <aside>
                <StaticImage src={"../images/product/design-studio.png"}
                             alt={"Hyperstack Design Studio"}/>
            </aside>
        </section>

        <section>
            <aside>
                <h3 className="title"><span className="text-blue">Endlessly</span> extensible</h3>
                <p>Integrate Hyperstack’s digital credentialing tools with popular learning management platforms around
                    the world. With our easy to use integration module, connect to any app or service within 10
                    minutes.</p>
            </aside>
            <aside>
                <StaticImage src={"../images/product/integrations.png"} alt={"Hyperstack Inteagrations"}/>
            </aside>
        </section>

        <section className={"section--row hcenter"}>
            <Link className="micro" href={"/features"}>View all features
                <NorthEast color={"#666"} size={"18px"}/>
            </Link>
        </section>
        <section className={"section--row hcenter mgb10"}>
            <h2 className="title">A plan for every industry</h2>
            <p className={"text-center"}>Hyperstack has affordable plans that will meet your needs.<br/>
                Whether you need to send 500 or 5 million digital credentials, we got you covered.
            </p>

            <div className="switch" style={{width: "350px"}}>
                <p style={{width: "50%"}} className={plantype === 0 ? "switch-selected" : null}
                   onClick={() => setPlantype(0)}>Monthly</p>
                <p style={{width: "50%"}} className={plantype === 1 ? "switch-selected" : null}
                   onClick={() => setPlantype(1)}>Annually</p>
            </div>
            <div className={"slabs"}>
                <div className={"slab"} style={{background: `#CCFFCC`}}>
                    <p className="slab-title">FREE</p>
                    <p>Essential for early stage</p>
                    <p className="slab-price">FREE</p>
                    <p>No Credit Card, Required</p>
                    <a className="slab-button" target="_blank" href="https://studio.thehyperstack.com/logon?ref=plans"
                       style={{background: `#40D440`}}>Start Now</a>
                    <p className="slab-recipients">20 Recipients yearly</p>
                    <p><span className="bold">Access to</span></p>
                    <ul>
                        <li>Unlimited Certificates to Each Recipient</li>
                        <li>Access to Design Studio</li>
                        <li>Analytics and Insights</li>
                        <li>Email Customization</li>
                        <li>Canva Integration</li>
                        <li>Student Wallet</li>
                        <li>1 Team Member</li>
                        <li>Open Badge 2.0 Support</li>
                    </ul>
                </div>
                <div className={"slab"} style={{background: `#CCE9FF`}}>
                    <p className="slab-title">GROWTH L1</p>
                    <p>Access to all tools</p>
                    <p className="slab-price">{plantype === 0 ? "49 USD" : "480 USD"}</p>
                    {plantype === 0 ? <p>Save 20% on Annual Plan</p> :
                        <p className="no-gap">20% Discount (<span className="strike">600 USD</span>)</p>}
                    <a className="slab-button" target="_blank" href="https://studio.thehyperstack.com/logon?ref=plans"
                       style={{background: `#4B92C8`}}>Start your free week</a>
                    <p className="slab-recipients">1000 Recipients yearly</p>
                    <p>All features in <span className="bold">Free,</span> plus</p>
                    <ul>
                        <li>Unlimited Certificates to Each Recipient</li>
                        <li>Custom Domain</li>
                        <li>Custom SMTP configuration</li>
                        <li>Branding and White-Labeling</li>
                        <li>API Access and Integration</li>
                        <li>Multi-Department Support</li>
                        <li>Feedback System</li>
                        <li>5 User Accounts</li>
                    </ul>
                </div>
                <div className={"slab"} style={{background: `#FFF1CC`}}>
                    <p className="slab-title">GROWTH L2</p>
                    <p>Access to all tools</p>
                    <p className="slab-price">{plantype === 0 ? "233 USD" : "2240 USD"}</p>
                    {plantype === 0 ? <p>Save 20% on Annual Plan</p> :
                        <p className="no-gap">20% Discount (<span className="strike">2800 USD</span>)</p>}
                    <a className="slab-button" target="_blank" href="https://studio.thehyperstack.com/logon?ref=plans"
                       style={{background: `#BE9016`}}>Start your free week</a>
                    <p className="slab-recipients">Unlimited Recipients</p>
                    <p>All features in <span className="bold">Free,</span> plus</p>
                    <ul>
                        <li>Unlimited Certificates to Each Recipient</li>
                        <li>Custom Domain</li>
                        <li>Custom SMTP configuration</li>
                        <li>Branding and White-Labeling</li>
                        <li>API Access and Integration</li>
                        <li>Multi-Department Support</li>
                        <li>Feedback System</li>
                        <li>5 User Accounts</li>
                    </ul>
                </div>

                <div className={"slab"} style={{background: `#D8CCFF`}}>
                    <p className="slab-title">ENTERPRISE</p>
                    <p>Highly dynamic</p>
                    <p className="slab-price">Let's chat</p>
                    {/*<p>Billed Annually</p>*/}
                    <p className="slab-recipients">Unlimited Recipients</p>
                    <a className="slab-button" href="/contact-us?ref=plans" style={{background: `#6F3ED1`}}>Contact
                        Sales</a>
                    <p>Everything in <span className="bold">Growth,</span> plus</p>
                    <ul>
                        <li>Advanced access controls</li>
                        <li>Experiments & Signal reports</li>
                        <li>SSO & automated provisioning</li>
                        <li>Seasonal & usage-based pricing</li>
                        <li>Dedicated customer success</li>
                        <li>Growth Strategy Support</li>
                    </ul>
                </div>
            </div>

            <div className="slab slab--horizontal">
                <div>
                    <h2 className="mgb10">Build your own Growth Plan</h2>
                    <p>With our plan builder, you can customize your plan according to your organization size and need.
                        You will get access to all of our tools without any restrictions or hidden charges.</p>
                </div>
                <Link to="/plan-builder" className="slab-button">Build your Plan</Link>
            </div>
            <Link className="micro" href={"/pricing"}>View all pricing in detail
                <NorthEast color={"#666"} size={"18px"}/></Link>

        </section>

        <section className={"section--row"}>
            <h2 className="title">Frequently asked<br/>
                questions, <span className={"text-blue"}>answered</span>.</h2>
            <Link className="micro" href={"/faq"}>Read all Custom Questions
                <NorthEast color={"#333"} size={"20px"}/></Link>
            <br/>

            {(faqs.map((faq, id) => {
                return (
                    <div className={"faq"} key={id}>
                        <div className={"question"} onClick={() => toggleFaq(id)}>
                            <h4>{faq.question}</h4>
                            <MaterialAdd color={"#666"} size={"18px"}/>
                        </div>
                        <div className={faqShown === id ? "answer" : "answer-hide"}>
                            <p>{faq.answer}</p>
                        </div>
                    </div>
                )
            }))}

        </section>


    </Layout>)

}
export const Head = () => <Seo
    title="Smart Digital Certificates & Badges | Hyperstack"
    description="Create smart digital certificates, badges & credentials with Hyperstack that you can share, manage, verify, control its privacy, design & deploy in minutes."
/>

export default IndexPage
